import axios from 'axios';
import {Buffer} from 'buffer';
import { RsaService } from './RsaService';
import { IRequestCreateCart } from './Type';

export const Service = {
    processSelected(object: HTMLElement) {
        let tail = '';
        if(location.pathname == '/OrderChairTo')
        {
          tail = 'To';
        }
        const boatId = sessionStorage.getItem('CurBoatId') || '0';
        let selectedAmount = sessionStorage.getItem(boatId+'selectedAmount'+tail) || 0;
        let amount = sessionStorage.getItem('amount') || 0;
        if (sessionStorage.getItem(boatId+tail+object.innerText.toString().trim()) !== null) {
          sessionStorage.removeItem(boatId+tail+object.innerText.toString().trim());
          selectedAmount = Number(selectedAmount) - 1;
          if(selectedAmount <=0)
          {
            selectedAmount = 0;
          }
          sessionStorage.setItem(boatId+'selectedAmount'+tail, selectedAmount.toString());
          if (object) {
            object.style.backgroundColor = '#fff';
          }
          return false;
        }
      
        if(amount == selectedAmount)
        {
          window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth'
          });
          return true;
        }
        else
        {
          selectedAmount = Number(selectedAmount) + 1;
          sessionStorage.setItem(boatId+'selectedAmount'+tail, selectedAmount.toString());
          sessionStorage.setItem(boatId+tail+object.innerText.toString().trim(), '1');
          sessionStorage.setItem(boatId+'Chair'+tail+selectedAmount,object.innerText.toString().trim()+';'+object.getAttribute('ticketId')+';'+object.getAttribute('ticketClass'));
          //console.log("___"+boatId+'selectedAmount'+tail) ;
          if (object) {
            object.style.backgroundColor = '#38D8EB';
          }
        }
        return false;
    },
    GetRoutes() {
        let data = Buffer.from(JSON.stringify({})).toString('base64'); 
        let objRsaService = new RsaService();
        const apiSigning = objRsaService.encryptMessage(data);
        return axios({
            url: 'https://backend.wvivu.com/public/get?data='+data,
            method: 'get',
            timeout: 8000,
            headers: {
                'api-code': 'routes',
                'api-signing': apiSigning
            }
        })
        .then(res => {
            return res.data.data;
        })
        .catch (err => console.error(err))
    },
    GetBanner() {
        let data = Buffer.from(JSON.stringify({})).toString('base64');  
        let objRsaService = new RsaService();
        const apiSigning = objRsaService.encryptMessage(data);
        return axios({
            url: 'https://backend.wvivu.com/public/get?data='+data,
            method: 'get',
            timeout: 32000,
            headers: {
                'api-code': 'banners',
                'api-signing': apiSigning
            }
        })
        .then(res => {
            return res.data.data;
        })
        .catch (err => console.error(err))
    },
    GetPrices(routeId: string) {
        let data = Buffer.from(JSON.stringify({routeId: routeId})).toString('base64');  
        let objRsaService = new RsaService();
        const apiSigning = objRsaService.encryptMessage(data);
        return axios({
            url: 'https://backend.wvivu.com/public/get?data='+data,
            method: 'get',
            timeout: 8000,
            headers: {
                'api-code': 'prices',
                'api-signing': apiSigning
            }
        })
        .then(res => {
            return res.data.data;
        })
        .catch (err => console.error(err))
    },
    SearchRoutes (routeId: string, selectedDate: string) {

        let data = Buffer.from(JSON.stringify({routeId: routeId, date: selectedDate})).toString('base64');  
        let objRsaService = new RsaService();
        const apiSigning = objRsaService.encryptMessage(data);
        console.log(apiSigning);
        return axios({
            url: 'https://backend.wvivu.com/public/get?data='+data,
            method: 'get',
            timeout: 16000,
            headers: {
                'api-code': 'voyages',
                'api-signing': apiSigning
            }
        })
        .then(res => {
            return res.data.data;
        })
        .catch (err => console.error(err))
    },
    BoatLayout (voyageId: string) {

        let data = Buffer.from(JSON.stringify({voyageId: voyageId})).toString('base64');  
        let objRsaService = new RsaService();
        const apiSigning = objRsaService.encryptMessage(data);
        return axios({
            url: 'https://backend.wvivu.com/public/get?data='+data,
            method: 'get',
            timeout: 32000,
            headers: {
                'api-code': 'boat_layout',
                'api-signing': apiSigning
            }
        })
        .then(res => {
            return res.data.data;
        })
        .catch (err => console.error(err))
    },
    CreateCart (objRequestCreateCart: IRequestCreateCart) {

        let data = Buffer.from(JSON.stringify(objRequestCreateCart)).toString('base64');  
        let objRsaService = new RsaService();
        const objResult = objRsaService.encryptLargeData(data);
        return axios.post('https://backend.wvivu.com/public/post',
            {
                data: data
            },
            {headers: {
                'api-code': 'create_cart',
                'api-signing': objResult.encryptedData,
                'api-aes-key': objResult.encryptedAESKey,
                'api-aes-iv': objResult.iv
            }})
        .then(res => {
            return res.data.data;
        })
        .catch (err => console.error(err))
    },
    GetHistory(bookPhone: string) {
        let data = Buffer.from(JSON.stringify({bookPhone: bookPhone, skip: 0, take: 10})).toString('base64');  
        let objRsaService = new RsaService();
        const apiSigning = objRsaService.encryptMessage(data);
        return axios({
            url: 'https://backend.wvivu.com/public/get?data='+data,
            method: 'get',
            timeout: 16000,
            headers: {
                'api-code': 'carts',
                'api-signing': apiSigning
            }
        })
        .then(res => {
            return res.data.data;
        })
        .catch (err => console.error(err))
    },
    GetZaloURL(cartId: string, redirectUrl: string) {
        let data = Buffer.from(JSON.stringify({cartId: cartId, redirectUrl: redirectUrl})).toString('base64');  
        let objRsaService = new RsaService();
        const objResult = objRsaService.encryptLargeData(data);
        return axios.post('https://backend.wvivu.com/zlp/init',
            {
                cartId: cartId, redirectUrl: redirectUrl
            },
            {headers: {
                'api-signing': objResult.encryptedData,
                'api-aes-key': objResult.encryptedAESKey,
                'api-aes-iv': objResult.iv
            }})
        .then(res => {
            return res.data;
        })
        .catch (err => console.error(err))
    },
    CheckZaloStatus(paras: string) {
        console.log('https://backend.wvivu.com/zlp/query'+paras)
        return axios({
            url: 'https://backend.wvivu.com/zlp/query'+paras,
            method: 'get',
            timeout: 16000,
        })
        .then(res => {
            return res.data;
        })
        .catch (err => console.error(err))
    },
}